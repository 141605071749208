import React, { lazy, Suspense /*useState, useEffect*/ } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
/*import { ClimbingBoxLoader } from 'react-spinners';*/

import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './theme';

// Layout Blueprints

import { DoverLeftSidebar } from './layout-blueprints';

const Dover = lazy(() => import('./example-pages/Dover'));
const DoverClientPortal = lazy(() => import('./example-pages/Dover-ClientPortal'));
const DoverContact = lazy(() => import('./example-pages/Dover-Contact'));
const DoverPortfolio = lazy(() => import('./example-pages/Dover-Portfolio'));
const DoverService = lazy(() => import('./example-pages/Dover-Service'));
const DoverStory = lazy(() => import('./example-pages/Dover-Story'));
const PageError404 = lazy(() => import('./example-pages/PageError404'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const SuspenseLoading = () => {

    return (
      <>

      </>
    );
  }

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Route path={[
              '/Dover-ClientPortal',
              '/Dover-Contact',
              '/Dover-Portfolio',
              '/Dover-Service',
              '/Dover-Story',
              '/PageError404'
            ]}>
              <DoverLeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/PageError404" component={PageError404} />
                    <Route path="/Dover-ClientPortal" component={DoverClientPortal} />
                    <Route path="/Dover-Contact" component={DoverContact} />
                    <Route path="/Dover-Portfolio" component={DoverPortfolio} />
                    <Route path="/Dover-Service" component={DoverService} />
                    <Route path="/Dover-Story" component={DoverStory} />
                  </motion.div>
                </Switch>
              </DoverLeftSidebar>
            </Route>
            <Redirect exact from="/Dover" to="/" />
            <Route
              path={['/']}>
              <DoverLeftSidebar>
                <Switch>
                  <Route exact path="/" component={Dover} />
                  <Route path="*" component={PageError404} />
                </Switch>
              </DoverLeftSidebar>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
