import React from 'react';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

const DoverHeaderMenu = () => {

  return (
    <>
      <div className="app-header-menu">
        <Button
          size="medium"
          className="btn-transition-none text-white mr-3 font-size-lg"
          component={NavLink}
          activeClassName="active"
          to='/Dover-Story'
          variant="text"
        >
          <Fade up duration={1000} distance={'1rem'}>
            Our Story
          </Fade>
        </Button>
        <Button
          size="medium"
          className="btn-transition-none text-white mr-3 font-size-lg"
          component={NavLink}
          activeClassName="active"
          to='/Dover-Service'
          variant="text">
          <Fade up duration={1000} distance={'1rem'} delay={250}>
            How We Serve
          </Fade>
        </Button>
        <Button
          size="medium"
          className="btn-transition-none text-white mr-3 font-size-lg"
          component={NavLink}
          activeClassName="active"
          to='/Dover-Portfolio'
          variant="text">
          <Fade up duration={1000} distance={'1rem'} delay={500}>
            Portfolio
          </Fade>
        </Button>
        <Button
          size="medium"
          className="btn-transition-none text-white mr-3 font-size-lg"
          component={NavLink}
          activeClassName="active"
          to='/Dover-ClientPortal'
          variant="text">
          <Fade up duration={1000} distance={'1rem'} delay={750}>
            Client Portal
          </Fade>
        </Button>
        <Button
          size="medium"
          className="btn-transition-none text-white mr-3 font-size-lg"
          component={NavLink}
          activeClassName="active"
          to='/Dover-Contact'
          variant="text">
          <Fade up duration={1000} distance={'1rem'} delay={1000}>
            Contact
          </Fade>
        </Button>
      </div>
    </>
  );
};

export default DoverHeaderMenu;
