import React from 'react';

import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem } from '@material-ui/core';

import { connect } from 'react-redux';

const Footer = (props) => {
  const { footerShadow, footerBgTransparent } = props;
  return (
    <>
      <div
        className={clsx('app-footer justify-content-center align-items-center flex-column text-white font-size-md pt-4', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent
        })}>
        <div className='mb-2 d-flex footerFlex'>
          <div>Adam C. Dover, LLC.&nbsp;</div>
          <div>All Rights Reserved. © {new Date().getFullYear()}</div>
        </div>
        <div className="divider border-2 d-none d-md-block rounded-circle border-white bg-second opacity-4 mx-auto w-50" />
        <List
          component="div"
          className="nav-transparent-alt text-nowrap d-flex justify-content-center p-0 mt-1">
          <ListItem
            component="a"
            button
            className="text-white-50 socialIcon"
            href="https://www.houzz.com/pro/dovercustombuilders"
            target="_blank"
            title="Houzz">
            <FontAwesomeIcon
              icon={['fab', 'houzz']}
              className="font-size-lg"
            />
          </ListItem>
          <ListItem
            component="a"
            button
            className="text-white-50 socialIcon"
            href="https://www.instagram.com/dovercustombuilders/"
            target="_blank"
            title="Instagram">
            <FontAwesomeIcon
              icon={['fab', 'instagram']}
              className="font-size-lg"
            />
          </ListItem>
          <ListItem
            component="a"
            button
            className="text-white-50 socialIcon"
            href="https://www.facebook.com/dovercustombuilders/"
            target="_blank"
            title="Facebook">
            <FontAwesomeIcon
              icon={['fab', 'facebook']}
              className="font-size-lg"
            />
          </ListItem>
          <ListItem
            component="a"
            button
            className="text-white-50 socialIcon"
            href="https://www.youtube.com/channel/UCwwM6xLkKfQ4m5IOP_4OjtA"
            target="_blank"
            title="YouTube">
            <FontAwesomeIcon
              icon={['fab', 'youtube']}
              className="font-size-lg"
            />
          </ListItem>
        </List>
        <div className="text-white-50 mt-4 mb-3">
          Crafted with <span className='text-white' title="Juice">🧃</span> by{' '}
          <a
            href="https://CivicDX.com"
            target="_blank"
            className='civicURL text-white-50 '
            rel="noopener noreferrer">
            Civic DX
          </a>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

export default connect(mapStateToProps)(Footer);
