import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

import BusinessCenterTwoToneIcon from '@material-ui/icons/BusinessCenterTwoTone';
import ChatTwoToneIcon from '@material-ui/icons/ChatTwoTone';
import WebTwoToneIcon from '@material-ui/icons/WebTwoTone';
import SupervisedUserCircleTwoToneIcon from '@material-ui/icons/SupervisedUserCircleTwoTone';
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import HomeWorkTwoToneIcon from '@material-ui/icons/HomeWorkTwoTone';

const DoverSidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  return (
    <>
      <PerfectScrollbar className="noScrollBar">
        <div className="sidebar-navigation">
          <ul>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/"
                exact={true}>
                <span className="sidebar-icon">
                  <HomeTwoToneIcon />
                </span>
                <span>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/Dover-Story">
                <span className="sidebar-icon">
                  <SupervisedUserCircleTwoToneIcon />
                </span>
                <span>Our Story</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/Dover-Service">
                <span className="sidebar-icon">
                  <HomeWorkTwoToneIcon />
                </span>
                <span>How We Serve</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/Dover-Portfolio">
                <span className="sidebar-icon">
                  <BusinessCenterTwoToneIcon />
                </span>
                <span>Portfolio</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/Dover-ClientPortal">
                <span className="sidebar-icon">
                  <WebTwoToneIcon />
                </span>
                <span>Client Portal</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/Dover-Contact">
                <span className="sidebar-icon">
                  <ChatTwoToneIcon />
                </span>
                <span>Contact</span>
              </NavLink>
            </li>
          </ul>
          <div className="sidebar-menu-box doverContacts">
            <div className="sidebar-header text-center text-capitalize">
              <span className='font-size-lg'>We'd love to hear from you!</span>
            </div>
            <ul>
              <li>
                <a
                  className="font-size-lg font-weight-normal"
                  href="tel:4045570232">
                  <span className="sidebar-item-label text-center w-100">(404) 557-0232</span>
                </a>
              </li>
              <li>
                <a
                  className="font-size-md font-weight-normal"
                  href="mailto:info@dovercustombuilders.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="sidebar-item-label text-center w-100">info@dovercustombuilders.com</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,

  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(DoverSidebarMenu);
