import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { setSidebarToggle, setSidebarToggleMobile } from '../../reducers/ThemeOptions';

const DoverSidebarHeader = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const {
    sidebarToggleMobile,
    setSidebarToggleMobile
  } = props;

  return (
    <>
      <div className="app-sidebar-dover--header scrollShadow">

        <Button
          className={clsx('navbar-toggler hamburger hamburger--elastic is-active dover-hamburger')}
          onClick={toggleSidebarMobile}>
          <DoubleArrowIcon className={sidebarToggleMobile ? '' : 'move'} />
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(DoverSidebarHeader);
